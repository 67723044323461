/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "adding-base-styles",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#adding-base-styles",
    "aria-label": "adding base styles permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Adding base styles"), "\n", React.createElement(_components.p, null, "Best practices for adding your own global base styles on top of xstyled."), "\n", React.createElement("carbon-ad"), "\n", React.createElement(_components.p, null, "Even if xstyled already includes a set of base style with ", React.createElement(_components.a, {
    href: "/docs/preflight/"
  }, "Preflight"), ", you could need to add more base styles in your project."), "\n", React.createElement(_components.h2, {
    id: "create-global-styles",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#create-global-styles",
    "aria-label": "create global styles permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Create Global Styles"), "\n", React.createElement(_components.p, null, "xstyled offers a unified method for styled-components and Emotion to create global styles."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import { createGlobalStyle } from '@xstyled/...'\n\nconst GlobalStyle = createGlobalStyle`\n  body {\n    color: #333;\n  }\n`\n\nfunction App() {\n  return (\n    <>\n      <GlobalStyle />\n      {/* ... */}\n    </>\n  )\n}\n")), "\n", React.createElement(_components.h2, {
    id: "automatic-theming",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#automatic-theming",
    "aria-label": "automatic theming permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Automatic Theming"), "\n", React.createElement(_components.p, null, "Of course automatic theming described in ", React.createElement(_components.a, {
    href: "/docs/magic-styled-components/"
  }, "enhanced styled documentation"), " also applied in ", React.createElement(_components.code, null, "createGlobalStyle"), ":"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import { createGlobalStyle } from '@xstyled/...'\n\nconst GlobalStyle = createGlobalStyle`\n  body {\n    /* Use theme.colors['text-color'] */\n    color: text-color;\n  }\n`\n\nfunction App() {\n  return (\n    <>\n      <GlobalStyle />\n      {/* ... */}\n    </>\n  )\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
